const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://docs.trustap.com",
		"gaTrackingId": null
	},
	"header": {
		"logo": "https://www.trustap.com/wp-content/uploads/2024/10/logo.svg",
		"title": "TrustAPI Documentation",
		"githubUrl": "",
		"helpUrl": "mailto:support@trustap.com",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
	},
	"sidebar": {
		"forcedNavOrder": [
      "/index",
			"/introduction",
			"/tutorials",
			"/guides",
			"/explanations",
			"/references",
		],
		"links": [
			{ "text": "", "link": ""},
		],
        "ignoreIndex": false
	},
	"siteMetadata": {
		"title": "TrustAPI Documentation | Trustap",
		"description": "Documentation built with mdx. Powering docs.trustap.com.",
		"ogImage": null,
		"docsLocation": "",
		"favicon": ""
	},
};

module.exports = config;
